<template>
    <div>
        <Users :headers="headers"/>
    </div>
</template>

<script>
export default {
    components:{
        Users: () => import('./shared/users')
    },

    data() {
        return {
            headers: [
                {
                    text: '',
                    value: 'photoUrl'
                },
                {
                    text: 'الإسم الثلاثي',
                    value: 'fullName'
                },
                {
                    text: 'إسم المستخدم',
                    value: 'userName'
                },
                {
                    text: 'البريدالإلكتروني',
                    value: 'email'
                },
                {
                    text: 'رقم الهاتف',
                    value: 'phoneNumber',
                    align: 'right'
                },
                {
                    text: 'المحافظة',
                    value: 'governorate.name'
                },
                {
                    text: 'المنطقة',
                    value: 'area.name'
                },
                {
                    text: 'المخزن',
                    value: 'warehouse.name'
                },
                // {
                //     text: 'الصلاحية',
                //     value: 'authGroup.name'
                // },
                {
                    text: 'حالة الحساب',
                    value: 'isActive'
                },
                {
                    text: '',
                    value: 'actions'
                }
            ]
        }
    },
}
</script>
